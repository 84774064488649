import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
// ** Parts
import NavBar from "./../partials/header/AuthNavBar";
import Footer from "./../partials/footer/Footer";
// ** assets
import help_center from "../assets/images/help_center6.webp";
// ** css
import "../assets/styles/helpCenter.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Helmet } from 'react-helmet';
// ** ==>
function HelpCenter() {

  const keywordsString = "Etudier en France,Inscription,Economie,Expérience,Voyage en Europe,etude en tunisie,Étudiants internationaux,DIplome internationale,Bacheliers,Bac Français,Expérience étudiante,Diplôme français,Reconnaissance internationale,étudier à l'étranger,accompagnement orientation,accompagnement orientation terminale,accompagnement parcoursup,accompagnement campus france,accompagnement campusart parcoursup,Licences,Mastères,Doctorat,Sciences,Ingénierie,Recherche en France,cout des etudes en tunisie,Excellence académique,Universités Françaises,Grandes écoles Françaises,Programmes d’études,Campus universitaire,visa étudiant sans compte bloqué,meilleur université privé en tunisie,Etudier en Tunisie,etudier en tunisie sans bac,Diplome homologué,etudier et travailler a l'etranger,Visa,Frais de scolarité,Formation de qualité,Logement,Système éducatif international,Conseils pour études à l’étranger,Démarches administratives,Carte de séjour,Hébergement,Orientation académique,Education internationale,accompagnement pour etudier en france,aide pour trouver une université en france,aide pour trouver une université homologué,études à l'étranger après le bac,étudier à l'étranger université,les universités en france qui acceptent les étrangers,trouver des etudes à l etrangers sans bac,Etudier à l'étranger après le bac,les meilleures universités privées en france,les meilleures universités privées en tunisie,étudier à l'étranger sans le bac,etudier a l'etranger sans bac"
  ;

  const { t } = useTranslation();

  useEffect(()=>{
    window.scrollTo(0, 0);
},[])

const isMobile = useMediaQuery({ maxWidth: 992 });

const isDesMobile = (
  <>
    <Helmet>
        <title>Centre d'aide | Questions fréquemment posées sur les études à l'étranger | Success Study</title>
        <meta name="description" content="Trouvez des réponses aux questions courantes sur les études à l'étranger. Découvrez les avantages des études en France , en Tunisie et à l'international, avec des services d'accompagnement pour faciliter votre parcours. Obtenez des informations sur l'inscription, l'hébergement et les programmes d'études." />
        <meta name="keywords" content={keywordsString} />
      </Helmet>
      <NavBar />



      <main>
        <div
          className="help_center_container "
          style={{ backgroundImage: `url(${help_center})`   }}
        >
          <div className="help_center_content"  >
            <h3 className="help_center_title title_main color_main mb-4">
              Success Study
            </h3>
            <h6 className="help_center_sub_title color_main title_second">
              {t('faq.title')}
            </h6>
          </div>
        </div>
        <div className="faq_section">
        <h3 className="faq_title title_main color_second">
          {t("faq.section_title_1")}
        </h3>
        <Row className="">
          <Col lg={6} md={12}   className="my-3">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign: "left" }}              >
                {t("faq.section_title_22")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                {t('faq.section_text_1', { returnObjects: true }).map((item) => (
                  <li key={item.key} className="dropdown-item help_center_active_drop_down color_main title_second text-wrap f-12" dangerouslySetInnerHTML={{
                    __html: t(item.text),
                  }}>
                   
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={6} md={12}   className="my-3">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign: "left" }}              >
                {t("faq.section_title_2")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                {t('faq.section_text_2', { returnObjects: true }).map((item) => (
                  <li key={item.key} className="dropdown-item help_center_active_drop_down color_main title_second text-wrap f-12" dangerouslySetInnerHTML={{
                    __html: t(item.text),
                  }}>
                   
                  </li>
                ))}
              </ul>

            </div>
          </Col>
        </Row>
        <Row className="">
          <Col lg={6} md={12}  className="my-3">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign: "left" }}              >
                {t("faq.section_title_3")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                <li
                  className="dropdown-item help_center_active_drop_down color_main title_second text-wrap"
                  dangerouslySetInnerHTML={{
                    __html: t("faq.section_desc_3"),
                  }}
                />
              </ul>
            </div>
          </Col>
          <Col lg={6} md={12}  className="my-3">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign: "left" }}              >
                {t("faq.section_title_4")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                <li
                  className="dropdown-item help_center_active_drop_down color_main title_second text-wrap"
                  dangerouslySetInnerHTML={{
                    __html: t("faq.section_desc_4"),
                  }}
                />
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}  className="my-3">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second text-wrap drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign: "left" }}
              >
                {t("faq.section_title_5")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                <li
                  className="dropdown-item help_center_active_drop_down color_main title_second text-wrap"
                  dangerouslySetInnerHTML={{
                    __html: t("faq.section_desc_5"),
                  }}
                />
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      </main>
      <Footer />
    </>
)
const isResMobile = (
  <>
      <Helmet>
      <title>Centre d'aide | Questions fréquemment posées sur les études à l'étranger | Success Study</title>
        <meta name="description" content="Trouvez des réponses aux questions courantes sur les études à l'étranger. Découvrez les avantages des études en France et en Tunisie, avec des services d'accompagnement pour faciliter votre parcours. Obtenez des informations sur l'inscription, l'hébergement et les programmes d'études." />
        <meta name="keywords" content={keywordsString} />
      </Helmet>
      <NavBar />
      <main>
        <div
          className="help_center_container "
          style={{ backgroundImage: `url(${help_center})` }}
        >
          <div className="help_center_content">
            <h3 className="help_center_title title_main color_main mb-4">
              Success Study
            </h3>
            <h6 className="help_center_sub_title color_main title_second">
              {t('faq.title')}
            </h6>
          </div>
        </div>
        <div className="faq_section">
        <h3 className="faq_title title_main color_second">
          {t("faq.section_title_1")}
        </h3>
        <Row className="mb-5 pb-5">
          <Col lg={6} md={12} className="mb-5">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("faq.section_title_22")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                {t('faq.section_text_1', { returnObjects: true }).map((item) => (
                  <li key={item.key} className="dropdown-item help_center_active_drop_down color_main title_second text-wrap f-12" dangerouslySetInnerHTML={{
                    __html: t(item.text),
                  }}>
                   
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={6} md={12} className="mb-5">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("faq.section_title_2")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                {t('faq.section_text_2', { returnObjects: true }).map((item) => (
                  <li key={item.key} className="dropdown-item help_center_active_drop_down color_main title_second text-wrap f-12" dangerouslySetInnerHTML={{
                    __html: t(item.text),
                  }}>
                   
                  </li>
                ))}
              </ul>

            </div>
          </Col>
        </Row>
        <Row className="mb-5 pb-5">
          <Col lg={6} md={12} className="mb-5">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("faq.section_title_3")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                <li
                  className="dropdown-item help_center_active_drop_down color_main title_second text-wrap"
                  dangerouslySetInnerHTML={{
                    __html: t("faq.section_desc_3"),
                  }}
                />
              </ul>
            </div>
          </Col>
          <Col lg={6} md={12} className="mb-5">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("faq.section_title_4")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                <li
                  className="dropdown-item help_center_active_drop_down color_main title_second text-wrap"
                  dangerouslySetInnerHTML={{
                    __html: t("faq.section_desc_4"),
                  }}
                />
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12} className="mb-5">
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle btn-elipss-css drop_down_title d-flex align-items-center justify-content-between w-100 title_second drop_down_title_border"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("faq.section_title_5")}
              </button>
              <ul className="dropdown-menu w-100 scrollin" aria-labelledby="dropdownMenuLink">
                <li
                  className="dropdown-item help_center_active_drop_down color_main title_second text-wrap"
                  dangerouslySetInnerHTML={{
                    __html: t("faq.section_desc_5"),
                  }}
                />
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      </main>
      <Footer />
    </>
)

  return (
    isMobile ? isDesMobile : isResMobile
  );
}

export default HelpCenter;
