import React, { useState,useEffect } from 'react';
import './App.css';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import valid from "../assets/images/valid2.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import { get_rib_info ,rib_create_Payment,get_rib_status} from "../redux/formation/formation.actions";
import { getPacks} from "../redux/pack/pack.actions";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector
import FileInput from "../components/FileInput";
    

function getPrice(pack,currency)
{
  if (currency==='dt')
  {
    return pack.dt
  }
  else if (currency==='eur') return pack.eur;
  else if (currency==='usd')return pack.use;
}
function renderPackageSelection(curr,price,packs,pack, handleClick,handleClick2, t,handleNext,handlePrev,nextPrev,type,handleConfirm,receiptData,files,setFiles) {
  return ([<div>
    <h1 className="landing-page-success-text-p">
                    {" "}
                    <span className="landing-page-success-text-p color_main ">
                      Choose
                    </span>{" "}
                    Your Package
                  </h1>
                  <h2 className="landing-page-success-text-desc-popings-p">
                   you can choose one of this packages to pass the payment preocess
                  </h2>
      <div className="container-p">
        
        <div
          className={`section-p ${pack === 0 ? 'selected-p' : ''}`}
          onClick={() => handleClick(0)}
        >
{ packs[0].nom}          
          <ul className="ul-p ul-list-border-green p-0 pe-4">
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                        {packs[0].desc[0]}
                      </li>
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                              {packs[0].desc[1]}
                      </li>
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                              {packs[0].desc[2]}
                      </li>
                      <li className="service-list-drop-down-p ">
                      <h1 className='price-p'>
                      <h1 className='price-p'>
      Prix de pack : <span>{price[0]}</span> <span>{curr}</span>
    </h1>
    </h1> 
                      </li>
                     
                    </ul>
                    
        </div>
        
        <div
          className={`section-p ${pack ===1 ? 'selected-p' : ''}`}
          onClick={() => handleClick(1)}
        >
          {packs[1].nom}
          <ul className="ul-p ul-list-border-green p-0 pe-4">
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                              {packs[1].desc[0]}
                      </li>
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                              {packs[1].desc[1]}
                      </li>
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                              {packs[1].desc[2]}
                      </li>
                      <li className="service-list-drop-down-p ">
                     <h1 className='price-p'>
                     Prix de pack : <span>{price[1]}</span> <span>{curr}</span>
                      </h1> 
                      </li>

                    </ul>
        </div>
        
        <div
          className={`section-p ${pack === 2 ? 'selected-p' : ''}`}
          onClick={() => handleClick(2)}
        >
          {packs[2].nom}
          <ul className="ul-p ul-list-border-green p-0 pe-4">
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                              {packs[2].desc[0]}
                      </li>
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                              {packs[2].desc[1]}
                      </li>
                      <li className="service-list-drop-down-p ">
                      <div className="me-3">
                                <img src={valid} alt="valid" width={12} height={12} />
                              </div>
                              {packs[2].desc[2]}
                      </li>
                     
                      <li className="service-list-drop-down-p ">
                     <h1 className='price-p'>
                     Prix de pack : <span>{price[2]}</span> <span>{curr}</span>
                     </h1> 
                      </li>
                    </ul>
        </div>
     
      </div>
      <div className="btn-group-p">
       
        <button className="btn-next-step" onClick={() => {if (pack || pack === 0) handleNext()}}>
          Next
          <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '5px' }} />
        </button>
      </div>
        </div>,
      
      
      
      
      <div>
    <h1 className="landing-page-success-text-p">
                    {" "}
                    <span className="landing-page-success-text-p color_main ">
                      Now choose 
                    </span>{" "}
                    Your payment methode
                  </h1>
                  <h2 className="landing-page-success-text-desc-popings-p">
                   you can choose one of this payment methodes
                  </h2>
      <div className="container-p">
        
        <div
          className={`section-p ${type === 'RIB' ? 'selected-p' : ''}`}
          onClick={() => handleClick2('RIB')}
        >
          RIB
          <ul className="ul-p ul-list-border-green p-0 pe-4">
                      <li className="service-list-drop-down-p ">
                      Upon receiving the rib, the user can utilize it as a form of payment. Once the transaction is completed, they must promptly send the receipt to the Success Study team for verification and validation. This ensures transparency and accountability in the payment process, allowing for smooth transactions and accurate record-keeping.
                      </li>
                     
                 
                    </ul>
        </div>
        
        {/* <div
          className={`section-p ${type === 'ONLINE' ? 'selected-p' : ''}`}
          onClick={() => handleClick2('ONLINE')}
        >
          ONLINE
          <ul className="ul-p ul-list-border-green p-0 pe-4">
                      <li className="service-list-drop-down-p ">
                      users have the option to conveniently make online payments using various methods such as credit or debit cards, bank transfers, or digital wallets. 
                      </li>
                      
                    </ul>
        </div> */}
        
        
      </div>
      <div className="btn-group-p">
        {/* Previous button */}
        <button  className="btn-previous-step" onClick={() => handlePrev()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
          Previous
        </button>
        {/* Next button */}
        <button className="btn-next-step" onClick={() =>{if (type) handleNext()}}>
          Next
          <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '5px' }} />
        </button>
      </div>
        </div>
      
      
      
      
      
      
      
      
      
      
      ,      <div className='container2'>
      <h1 className="landing-page-success-text-p">
                      {" "}
                      <span className="landing-page-success-text-p color_main ">
                        Confirm
                      </span>{" "}
                      your payment
                    </h1>
                    <h2 className="landing-page-success-text-desc-popings-p">
                     now you can pay and upload you reciept here and the team of success study will call you soon
                    </h2>
        <div className="container-p2">
          
          <div
            className="section-p"
          >
             {receiptData && receiptData.formation && receiptData.universite&&receiptData.user&& (
                    <div className="receipt-table-p">
                        <table>
                            <tbody>
                            <tr>
                                    <td className="label">Platform:</td>
                                    <td className="value">SUCCESS STUDY</td>
                                </tr>
                            
                                <tr>
                                    <td className="label">User :</td>
                                    <td className="value">{receiptData.user.firstName}{' '}{receiptData.user.lastName}</td>
                                </tr>
                                <tr>
                                    <td className="label">Formation:</td>
                                    <td className="value">{receiptData.formation.nom}</td>
                                </tr>
                                <tr>
                                    <td className="label">University:</td>
                                    <td className="value">{receiptData.universite.nom}</td>
                                </tr>
                              
                                <tr>
                                    <td className="label">Amount:</td>
                                    <td className="value">{receiptData.montant} {receiptData.devise}</td>
                                </tr>
                                <tr>
                                    <td className="label">Type:</td>
                                    <td className="value">{type}</td>
                                </tr>
                                <tr>
                                    <td className="label">Pack:</td>
                                    <td className="value">{(pack||pack==0)&&packs[pack].nom} | {(pack||pack==0)&&price[pack]} {curr}</td>
                                </tr>
                                <tr>
                                    <td className="label">total amount:</td>
                                    <td className="value"> {(pack||pack==0)&&parseInt(price[pack])+parseInt(receiptData.montant)} {curr}</td>
                                </tr>
                                
                               
                            </tbody>
                        </table>
                    </div>
                )}
          </div>
      
        
         
  
        </div>
        <div className="container-p2">
          
          <div
            className="section-p"
          >
        <div className="receipt-table-p">
        <table>
                            <tbody>
                            <tr>
                                    <td className="label">RIB TN:</td>
                                    <td className="value">59 1010 5057 8900 2199 7832</td>
                                </tr>
                            
                                <tr>
                                    <td className="label">RIB DEVISE</td>
                                    <td className="value">59 1010 5057 1102 1047 8862</td>
                                </tr>
                             
                               
                            </tbody>
                        </table>

          </div>
          </div></div>
        <div style={{width:'50%',marginTop:'20px',marginBottom:"20px"} } >
          <p className="typo-sec text-capitalize title_second " >
       Read the informations , and if you accept please upload your reciept to finish the payment
     
      </p>

      <FileInput
        value={files.recu}
        setFile={setFiles}
        name="recu"
      />
          </div>
        <div className="btn-group-p" >
        {/* Previous button */}
        <button  className="btn-previous-step" onClick={() => handlePrev()}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
          Previous
        </button>
        {/* Next button */}
        <button className="btn-next-step" onClick={() =>{ handleConfirm()}}>
          Confirm
          <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '5px' }} />
        </button>
      </div>
        </div>]
  )}


function App() {
    const { t, i18n } = useTranslation();
    const { demID } = useParams();
  const [pack, setpack] = useState(null);
  const [currency, setcurrency] = useState(null);
 

  const [type, settype] = useState(null);
  const [curr, setCurr] = useState(null);

  const [price, setPrice] = useState([]);

  const [nextPrev, setNextPrev] = useState(0);
  const receiptData = useSelector(state => state.FormationReducer.receiptData);
  const status = useSelector(state => state.FormationReducer.status);
  const packs = useSelector(state => state.PackReducer.packs);
  const [files, setFiles] = useState(
    {
      recu : null
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_rib_status(demID));
    
  }, [demID])
  useEffect(() => {
    dispatch(get_rib_info(demID));
    console.log("status",status)
  }, [status])
  useEffect(() => {
    dispatch(getPacks());
    console.log("packs",packs)
  }, [receiptData])

  useEffect(() => {
    if(packs && receiptData)
    {
      console.log("this is packs",packs)
      console.log("this is reciept",receiptData)
      setcurrency(receiptData.devise)
 
      if(currency&&packs)
    {
     
console.log("this is packs",packs)
      if (currency.toLowerCase()==="dt") {  setPrice([packs[0].dt,packs[1].dt,packs[2].dt]); setCurr("TND")  }
      else if (currency.toLowerCase()==="eur") {   setPrice([packs[0].eur,packs[1].eur,packs[2].eur]);;setCurr("EUR")  }
      else if (currency.toLowerCase()==="usd") {   setPrice([packs[0].usd,packs[1].usd,packs[2].usd]);setCurr("USD")   }
      console.log(currency,price,curr)
      
    }

    }
  }, [packs,receiptData])

  useEffect(() => {
   console.log(pack)
  }, [pack])


  



  const handleClick = (sectionName) => {
    setpack(sectionName);
  };

  const handleClick2 = (sectionName) => {
    settype(sectionName);
  };

  const handleNext = () => {
    
    setNextPrev(nextPrev+1);
  };

  const handlePrev = () => {
    setNextPrev(nextPrev-1);
  };
  const handleConfirm = () => {
    dispatch(rib_create_Payment(files,demID,packs[pack],type,price[pack]));
  };




  return (
    <div>
                {status&&packs&& receiptData   && curr &&renderPackageSelection(curr,price,packs,pack, handleClick,handleClick2, t,handleNext,handlePrev,nextPrev,type,handleConfirm,receiptData,files,setFiles)[nextPrev]}
   
    </div>
  );
}

export default App;
