import keys from "./pack.keys";
import axios from "../../custom/axios";
import { toast } from "react-hot-toast";
const getPacks = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      console.log("calling getpacks from demande");
      const response = await axios.get(`/api/v1/demande/getpacks`);
      console.log("getting getpacks respone from demande", response);
      dispatch({
        type: keys.set_packs,
        value: response.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};



export { getPacks };
