
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "./Payment.css"; // Import your CSS file for styling
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector
import { check_Payment } from "../redux/formation/formation.actions";

function Test() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const receiptData = useSelector(state => state.FormationReducer.receiptData); // Assuming receiptData is stored in Redux state

    useEffect(() => {
        // Dispatch the check_Payment action to get the status of the transaction and fill the receipt data
        dispatch(check_Payment(id));
    }, [ id, dispatch]);

    return (
        <div className="test-container">
            <div className="test-content">
                <FontAwesomeIcon icon={faCheckCircle} className="test-icon" />
                <h1 className="test-heading">Payment Successful!</h1>
                <p className="test-message">Thank you for your purchase. Your transaction ID is: {id}</p>
                {receiptData && receiptData.formation && receiptData.universite&&receiptData.user&& (
                    <div className="receipt-table">
                        <table>
                            <tbody>
                            <tr>
                                    <td className="label">Platform:</td>
                                    <td className="value">SUCCESS STUDY</td>
                                </tr>
                            <tr>
                                    <td className="label">Order ID:</td>
                                    <td className="value">{id}</td>
                                </tr>
                                <tr>
                                    <td className="label">User :</td>
                                    <td className="value">{receiptData.user.firstName}{' '}{receiptData.user.lastName}</td>
                                </tr>
                                <tr>
                                    <td className="label">Formation:</td>
                                    <td className="value">{receiptData.formation.nom}</td>
                                </tr>
                                <tr>
                                    <td className="label">University:</td>
                                    <td className="value">{receiptData.universite.nom}</td>
                                </tr>
                              
                                <tr>
                                    <td className="label">Amount:</td>
                                    <td className="value">{receiptData.montant} {receiptData.devise}</td>
                                </tr>
                                <tr>
                                    <td className="label">Date:</td>
                                    <td className="value">{receiptData.createdAt.split('T')[0]}{'  at  '}{receiptData.createdAt.split('T')[1].split('.')[0]}</td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Test;