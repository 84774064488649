const keys = {
  payload: "formation_payload",
  set_formations: "setAllFormations",
  get_formations_country: "getFormationsByCountry",
  get_formation_id: "getFormationById",
  setDemands: "setDemands",
  set_receiptData: "set_receiptData",
  set_transactions: "set_transactions",
  set_status: "set_status",
};

export default keys;
