import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { Check } from "react-feather";
import study_in_france from "../assets/images/study_in_france.webp";
import visa_on_desk from "../assets/images/tun_sec3.webp";
import tour_effel from "../assets/images/paris-sec3.webp";
import "../assets/styles/discoverOurService.css";
import AuthNavBar from "./../partials/header/AuthNavBar";
import Footer from "../partials/footer/Footer";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';



// ** ==>
function StudyInFrance() {
  const keywordsString ="Etudier en France,Inscription,Economie,Expérience,Voyage en Europe,etude en tunisie,Étudiants internationaux,DIplome internationale,Bacheliers,Bac Français,Expérience étudiante,Diplôme français,Reconnaissance internationale,étudier à l'étranger,accompagnement orientation,accompagnement orientation terminale,accompagnement parcoursup,accompagnement campus france,accompagnement campusart parcoursup,Licences,Mastères,Doctorat,Sciences,Ingénierie,Recherche en France,cout des etudes en tunisie,Excellence académique,Universités Françaises,Grandes écoles Françaises,Programmes d’études,Campus universitaire,visa étudiant sans compte bloqué,meilleur université privé en tunisie,Etudier en Tunisie,etudier en tunisie sans bac,Diplome homologué,etudier et travailler a l'etranger,Visa,Frais de scolarité,Formation de qualité,Logement,Système éducatif international,Conseils pour études à l’étranger,Démarches administratives,Carte de séjour,Hébergement,Orientation académique,Education internationale,accompagnement pour etudier en france,aide pour trouver une université en france,aide pour trouver une université homologué,études à l'étranger après le bac,étudier à l'étranger université,les universités en france qui acceptent les étrangers,trouver des etudes à l etrangers sans bac,Etudier à l'étranger après le bac,les meilleures universités privées en france,les meilleures universités privées en tunisie,étudier à l'étranger sans le bac,etudier a l'etranger sans bac"
  ;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const { t } = useTranslation()
  return (
    <>
     <Helmet>
        <title>Services | Etudier en Tunisie et à l'international | Success Study</title>
        <meta name="description" content="Découvrez nos services pour étudier en Tunisie et à l'international. Obtenez un soutien complet pour les admissions, les visas et le logement. Vivez une expérience d'excellence académique et d'immersion culturelle avec notre expertise." />
        <meta name="keywords" content={keywordsString} />
      </Helmet>
      <AuthNavBar />

     
      <main className="page-our-ser" style={{ position: "relative" }}>
        <div className="h-auto">
          <img
            src={study_in_france}
            alt="study abroad"
            className="img-fluidDesc2"
          />
          <div className="position-absolute page_title_container studyInFranceContainer" style={{ margin: "0!important" }}>
            <h3 className="discover_title color_main text-center title_main mb-5 w-100">
              Success Study Services
            </h3>
            <h6 className="discover_sub_title color_main title_second text-center mb-5 w-100">
              {t('service.title')}
            </h6>

          </div>

        </div>

        <div className="container_2 position-relative margin-barcha2">
          <Row className="">
            <Col md={7} xs={12}>
              <h2 className="title_main color_second title_1 mb-5">
                {t('service.heading_one')}
              </h2>
              <ul className="ul-list-border-green p-0 ps-4">
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">{t('service.service_1')}</h3>
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs "> {t('service.service_2')}</h3>
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  {t('service.service_3')}
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">{t('service.service_4')}</h3>
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">{t('service.service_5')} </h3> 
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs "> {t('service.service_6')}</h3>
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">{t('service.service_7')}</h3> 
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">{t('service.service_8')}</h3> 
                </li>
              </ul>
            </Col>

            <Col md={1} xs={12} className="justify-content-top"></Col>
            <Col md={4} xs={12} className="justify-content-top">
              <div className="img-sec-desk">
                <img src={visa_on_desk} alt="study abroad" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="container_2 mt-4">
          <Row>
            <Col md={7} xs={12} className="">
              <h2 className="title_main color_second title_1 mb-5">
                {t('service.int_service')}
              </h2>


              <ul className="ul-list-border-green p-0 ps-4">
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">  {t('service.service_1')}</h3>
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">{t('service.service_2')}</h3> 
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs "> {t('service.service_3')} </h3>
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs "> {t('service.service_4')}</h3>
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">{t('service.service_5')}</h3> 
                </li>
                <li className="service-list-drop-downs ">
                  <div className="me-3">
                    <Check size={25} />
                  </div>
                  <h3 className="service-list-drop-downs ">{t('service.service_6')}</h3> 
                </li>
              
              </ul>
            </Col>
            <Col md={1} xs={12} className="justify-content-top"></Col>
            <Col md={4} xs={12} className="justify-content-top">
              <div className="img-sec-desk">
                <img src={tour_effel} alt="study abroad" />
              </div>
            </Col>
          </Row>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default StudyInFrance;
