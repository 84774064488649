import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import "../assets/styles/discoverOurService.css";
import AuthNavBar from "../partials/header/AuthNavBar";
import Footer from "../partials/footer/Footer";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import study_in_france2 from "../assets/images/study_in_france2.webp";
import poster from "../assets/images/poster.webp";
import vid1 from "../assets/guide1.webm";
import vid2 from "../assets/guide2.webm";
import "../../node_modules/video-react/dist/video-react.css";
import  ReactPlayer  from 'react-player';
import { useMediaQuery } from "react-responsive";

// ** ==>
function StudyInFrance() {
  const keywordsString = "Etudier en France,Inscription,Economie,Expérience,Voyage en Europe,etude en tunisie,Étudiants internationaux,DIplome internationale,Bacheliers,Bac Français,Expérience étudiante,Diplôme français,Reconnaissance internationale,étudier à l'étranger,accompagnement orientation,accompagnement orientation terminale,accompagnement parcoursup,accompagnement campus france,accompagnement campusart parcoursup,Licences,Mastères,Doctorat,Sciences,Ingénierie,Recherche en France,cout des etudes en tunisie,Excellence académique,Universités Françaises,Grandes écoles Françaises,Programmes d’études,Campus universitaire,visa étudiant sans compte bloqué,meilleur université privé en tunisie,Etudier en Tunisie,etudier en tunisie sans bac,Diplome homologué,etudier et travailler a l'etranger,Visa,Frais de scolarité,Formation de qualité,Logement,Système éducatif international,Conseils pour études à l’étranger,Démarches administratives,Carte de séjour,Hébergement,Orientation académique,Education internationale,accompagnement pour etudier en france,aide pour trouver une université en france,aide pour trouver une université homologué,études à l'étranger après le bac,étudier à l'étranger université,les universités en france qui acceptent les étrangers,trouver des etudes à l etrangers sans bac,Etudier à l'étranger après le bac,les meilleures universités privées en france,les meilleures universités privées en tunisie,étudier à l'étranger sans le bac,etudier a l'etranger sans bac";
  const isMobile = useMediaQuery({ maxWidth: 992 });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const { t } = useTranslation()
  return (
    <>
     <Helmet>
        <title>Guide | Guide d'utilisation pour les étudiants et les universités | Success Study</title>
        <meta name="description" content="Naviguez sans effort dans le processus d'inscription et de connexion grâce à notre guide complet conçu pour les étudiants et les universités. Découvrez les procédures d'inscription, les exigences de visa, les programmes académiques et bien plus encore. Commencez votre parcours académique dès aujourd’hui !" />
        <meta name="keywords" content={keywordsString} />
      </Helmet>
      <AuthNavBar />

     
      <main className="page-our-ser" style={{ position: "relative" }}>
        <div className="h-auto">
          <img
            src={study_in_france2}
            alt="study abroad"
            className="img-fluidDesc2"
            
          />
          <div className="position-absolute page_title_container2 studyInFranceContainer" style={{ margin: "0!important" }}>
            <h3 className="discover_title color_main text-center title_main mb-5 w-100">
            {t('service.guide_title')}
            </h3>
            <h6 className="discover_sub_title color_main title_second text-center mb-5 w-100">
              {t('service.guide_subtitle')}
            </h6>

          </div>

        </div>

        <div className="container_2 position-relative margin-barcha2" style={{top:"100px"}}>
        <Row>
        <Col md={7} xs={12} className="d-flex justify-content-center align-items-center">
          <h2 className="title_main color_second title_1 mb-5">
            {t('service.guide_st')}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12} className="d-flex justify-content-center">
          <div > 
  <ReactPlayer controls={true} url={vid2} light={poster}  width={isMobile? "350px":"900px"} height={isMobile? "199px":"508px"} style={{  border: '2px solid #FFB703',
    borderRadius: '7px',
    overflow: 'hidden',
   }} />
</div>
        </Col>
      </Row>
        </div>
        <div className="container_2 " style={{marginTop:isMobile?"150px":"0"}}>
      <Row>
        <Col md={7} xs={12} className="d-flex justify-content-center align-items-center">
          <h2 className="title_main color_second title_1 mb-5">
            {t('service.guide_uni')}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12} className="d-flex justify-content-center">
        <div > 
  <ReactPlayer controls={true} url={vid1} light={poster} width={isMobile? "350px":"900px"} height={isMobile? "199px":"508px"} style={{  border: '2px solid #FFB703',
    borderRadius: '7px',
    overflow: 'hidden',
   }} />
</div>
        </Col>
      </Row>
    </div>
      </main>
      <Footer />
    </>
  );
}

export default StudyInFrance;
