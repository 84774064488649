import keys from "./formation.keys";
import axios from "../../custom/axios";
import { Mquery } from "../../functions/MakeQuery";
import { toast } from "react-hot-toast";
const Getformations = (query = {}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const paginationQuery = {
        ...query,
        page: query.p, // Assuming your pagination parameter is named 'p'
        limit: query.l, // Assuming your pagination parameter is named 'l'
      };
      const response = await axios.get(
        `/api/v1/university/getFormations`,
        Mquery(paginationQuery)
      );
      dispatch({
        type: keys.set_formations,
        value: response.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const GetDemands = (_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.get(`/api/v1/demande/getAll`);
      console.log(response);
      dispatch({
        type: keys.setDemands,
        value: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const GetFormationByCountry = (city) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.get(
        `/api/v1/university/getFormation/${city}`
      );
      console.log(response.data.data);
      dispatch({
        type: keys.get_formations_country,
        value: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};
const GetFormationById = (Univid, formaId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.get(
        `/api/v1/university/getFormation/${Univid}/${formaId}`
      );
      console.log(response.data.data);
      dispatch({
        type: keys.get_formation_id,
        value: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const check_ribPayment = (DemandeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.get(
        `/api/v1/demande/rib_payment/${DemandeId}`
      );
      window.open(response.data.data, "_blank");
      console.log("(", response.data.data, ")");
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const check_Payment = (trans_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.get(
        `/api/v1/demande/check_payment/${trans_id}`
      );
      console.log("))))))))))))))))))))");
      console.log("(", response.data.data, ")");
      dispatch({
        type: keys.set_receiptData,
        value: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const get_rib_info = (demId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.get(`/api/v1/demande/get_rib_info/${demId}`);
      console.log("))))))))))))))))))))");
      console.log("(", response.data.data, ")");
      dispatch({
        type: keys.set_receiptData,
        value: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const rib_create_Payment = (files, demId, packs, type, packPrice) => {
  const formData = new FormData();

  formData.append("recu", files.recu);
  console.log(demId, formData);
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.post(
        `/api/v1/demande/rib_create_Payment/${demId}/${packs.nom}/${type}/${packPrice}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      window.location.href = response.data.data;
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(`Profile Changed successfully`);
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const getUniTrans = (uni_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.get(`/api/v1/demande/getUniTrans/${uni_id}`);

      dispatch({
        type: keys.set_transactions,
        value: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const get_rib_status = (demId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });

      const response = await axios.get(
        `/api/v1/demande/get_rib_status/${demId}`
      );

      dispatch({
        type: keys.set_status,
        value: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

export {
  Getformations,
  GetFormationByCountry,
  GetFormationById,
  GetDemands,
  check_ribPayment,
  check_Payment,
  get_rib_info,
  rib_create_Payment,
  getUniTrans,
  get_rib_status,
};
