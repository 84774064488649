import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, InputGroup, Label, Row } from "reactstrap";
import { CreateFormation } from "../../../redux/university/university.actions";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Tabs } from "react-bootstrap";
import { GetDemandsByIdStudent } from "../../../redux/user/user.actions";
import { InitialState } from "../../../redux/user/user.reducer";
import { Button } from "primereact/button";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Formation.css";
import { ReactComponent as Icon } from "../../../assets/images/del.svg";
import { useMediaQuery } from "react-responsive";
import OpenDescription from "./popups/OpenDescription";
import { Link } from "react-router-dom";
import { check_ribPayment} from "../../../redux/formation/formation.actions";





function Formation(props) {
  const Arr_devises=[
    "DT",
    "USD", // Dollar américain
    "EUR", // Euro
    "GBP", // Livre sterling
    "JPY", // Yen japonais
    "CAD", // Dollar canadien
    "CHF", // Franc suisse
    "AUD", // Dollar australien
    "NZD", // Dollar néo-zélandais
    "CNY", // Yuan chinois
    "INR"  // Roupie indienne
  ];

  let emptyItem = { ...InitialState };
  const [Item, setItem] = useState(emptyItem);

  const formas = useSelector((state) => state.UserReducer.formations);
  const univId = props.univId;

  const [selectedButton, setSelectedButton] = useState("verfie");
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  
  };


  useEffect(() => {
    dispatch(GetDemandsByIdStudent(univId));
    console.log("---------------",univId)
  }, [univId]);
  useEffect(() => {
    console.log("---------------",formas)
  }, [formas]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer.user);
  const [Froma, setFroma] = useState({
    nom: "",
    description: "",
    avecBac: false,
    price:0,
    duree: 0,
    devise: "",
  });

  const [descDialogue, setDescDialog] = useState(false);


  const openDescDialogue = (row) => {
    setDescDialog(true);
    setItem({ ...row });
  };
  const handleClose = () => {
    setItem({ ...emptyItem });
    setDescDialog(false);
    dispatch(GetDemandsByIdStudent(univId));
  };

  const handle_change = (event) => {
    const { name, value } = event.target;
    setFroma({ ...Froma, [name]: value });
  };

  const handleSubmit = () => {
    if (!Froma.nom) {
      toast.error(t("uni_params.nomOblig"));
      return false;
    }
    if (!Froma.duree) {
      toast.error(t("uni_params.dureeOblig"));
      return false;
    }
    if (!Froma.description) {
      toast.error(t("uni_params.descOblig"));
      return false;
    }
    if (!Froma.devise) {
      toast.error(t("uni_params.deviseOblig"));
      return false;
    }
    dispatch(CreateFormation(Froma, user.Universite));
  };
  const isMobile = useMediaQuery({ maxWidth: 992 });

  const handleClick = (id) => {
    dispatch(check_ribPayment(id));
};
  const { t } = useTranslation();

  return (
    <div>
      <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
  <button
  className="button"
  
    onClick={() => handleButtonClick("refuse")}
  >
<span style={{ fontWeight: "bold", fontSize: "16px" }}>Refused</span>  </button>
  <button
  className="button"
    onClick={() => handleButtonClick("attente")}
  >
<span style={{ fontWeight: "bold", fontSize: "16px" }}>Pending</span>  </button>
  <button
 className="button"
      onClick={() => handleButtonClick("verfie")}
  >
<span style={{ fontWeight: "bold", fontSize: "16px" }}>Accepted</span>  </button>
</div>

      <br /> <br />
   <Row>
  <Col >
    {formas && formas.length > 0 ? (
      formas.map((item, key) => {
        if (item.statut === selectedButton)
          return (<div className="box_container formation_card_55 my-3 px-5">
          <div className="text-center">
            <h4 className="color_second mb-4 formation_title">
              {item.formation?.nom}
            </h4>
          </div>

          <div className="w-100">
            <h5 className="title_second color_main description_formation text-justify description-limit" style={{ wordWrap: 'break-word' }}>
              {item.formation?.description}
            </h5>
            <div className="d-flex align-items-end">
              <p style={{ fontSize: "14px", cursor: "pointer" }} className="color_second" onClick={() => openDescDialogue(item)}>{t('uni_params.OpenDescription')}</p>
              <button
                type="button"
                className="btn btn-outline-primary position-relative p-3 rounded-0 border-0"
                onClick={() => openDescDialogue(item.formation)}
                style={{ borderColor: '#4e86e4', color: '#4e86e4' }}
              >
                <i className="fas fa-book-reader fa-2x color_second"></i>
              </button>
            </div>

            <h5 className="title_second color_second description_formation" style={{ fontSize: "14px", maxWidth: "450px" }}>
              <span>{t("uni_params.price")}: {item.formation?.price} {item.formation?.devise}</span>
              <span style={{ margin: "0 10px" }}>|</span>
              <span>{t("uni_params.duration")}: {item.formation?.duree} {t("uni_params.week")}</span>
              <span style={{ margin: "0 10px" }}>|</span>
              <span>{item.formation?.avecBac ? t("user.with") : t("user.not_with")}</span>
            </h5>

            <div className="w-100 m-0 p-0">
              <div className="row">
                <div className="actions-right d-flex align-items-center justify-content-center">
                  {item.statut == "attente" &&
                    <div className="alert alert-warning py-4 text-center" style={isMobile ? { fontSize: "16px", width: "100%" } : { fontSize: "16px", width: "100%" }}>
                      {t('university.pen')}
                    </div>
                  }

                  {item.statut == "verfie" &&
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                      <div className="alert alert-success py-4 text-center" style={isMobile ? { fontSize: "16px", width: "100%" } : { fontSize: "16px", width: "70%" }}>
                        {item.Universite.nom} {t('university.accepted')}
                      </div>
                      <button to="/payment" className="alert alert-success py-4 text-center" style={isMobile ? { fontSize: "16px", width: "100%", textDecoration: 'none', color: 'inherit' } : { fontSize: "16px", width: "70%", width: "fit-content", textDecoration: 'none', color: 'inherit' }} onClick={() => handleClick(item._id)}>
                        Paiement
                      </button>
                    </div>
                  }

                  {item.statut == "refuse" &&
                    <div className="w-100 text-center">
                      <div className="alert alert-danger py-4" style={{ fontSize: "16px", width: "100%" }}>
                        <p>{t('university.rejected')}   </p>
                        <p style={{ fontWeight: "700" }}>{t('university.motif')}   </p>
                        <p>{item.motif}</p>
                      </div>
                      <Link to={`/send_demand/${item.formation?.Universite}/${item.formation?._id}`} style={isMobile ? { width: "100%", textAlign: "center" } : { width: "50%", textAlign: "center" }}>
                        <button className="btn send_btn_style main_btn" style={{ fontSize: "14px!important" }}>
                          {t('university.resend')}
                        </button>
                      </Link>
                    </div>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>)
            
      }
          )
      )
     : (
      <h1 className="city_title text-center d-inline" style={{ fontSize: "30px", display: "inline-block" }}>
        {t("city.not_available_formation")}
      </h1>
    )}
  </Col>
</Row>

  {Item && descDialogue && (
        <OpenDescription
          open={descDialogue}
          handleClose={handleClose}
          value={Item}
          title={`${Item}`}
        />
       
      )}
    </div>
  );
}

export default Formation;
