import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
// ** bootstrap
import { Col, Table } from "reactstrap";
// ** styles
import "../../../assets/styles/StudentSubmission.css";
import "../../../assets/styles/TransactionsTable.css";
import { getUniTrans } from "../../../redux/formation/formation.actions";
import { useDispatch, useSelector } from "react-redux";

// ** ==>
function Transaction({ univer }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transactions = useSelector(
    (state) => state.FormationReducer.transactions
  );
  useEffect(() => {
    if (univer.id) {
      dispatch(getUniTrans(univer.id));
      console.log("uniiiiiiiiiiiiiiiiiiiii", transactions, univer.id);
    }
  }, [univer]);

  useEffect(() => {
    console.log("transactions", transactions);
  }, [transactions]);

  return (
    <div className="submission_container">
      <Col md={8} xs={12}>
        <Table responsive borderless>
          <thead>
            <tr className="mb-5">
              <th className="thead_style color_main title_forth">
                {t("uniDash.user")}
              </th>
              <th className="thead_style color_main title_forth">
                {t("uniDash.date")}
              </th>
              <th className="thead_style color_main title_forth">Formation</th>
              <th className="thead_style color_main title_forth">
                {t("uniDash.rev")}
              </th>
            </tr>
          </thead>

          <tbody>
            {transactions.map((trans, index) => {
              return (
                trans && (
                  <tr>
                    <td>
                      <span className="tobody_td">
                        {trans.demande.User.firstName}{" "}
                        {trans.demande.User.lastName}
                      </span>
                    </td>
                    <td>
                      <span className="tobody_td">
                        {trans.createdAt.split("T")[0]}
                      </span>
                    </td>
                    <td>
                      <span className="tobody_td">
                        {trans.demande.formation.nom}
                      </span>
                    </td>
                    <td>
                      <span className="pricing_td">
                        {trans.montant} {trans.devise}
                      </span>
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </Table>
      </Col>
    </div>
  );
}

export default Transaction;
