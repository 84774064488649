import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import "./Fail.css"; // Import your CSS file for styling
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector
import { check_Payment } from "../redux/formation/formation.actions";
import { Link } from "react-router-dom";

function Test() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const receiptData = useSelector(state => state.FormationReducer.receiptData); // Assuming receiptData is stored in Redux state
    const [processing, setProcessing] = useState(true); // State to track processing status

    useEffect(() => {
        // Dispatch the check_Payment action to get the status of the transaction and fill the receipt data
        const fetchData = async () => {
            try {
                // Simulating API call delay
                await new Promise(resolve => setTimeout(resolve, 2000));
                // Dispatch action to check payment
                dispatch(check_Payment(id));
                setProcessing(false); // Set processing to false after successful response
            } catch (error) {
                console.error("Error processing transaction:", error);
                setProcessing(false); // Set processing to false on error
            }
        };

        fetchData();
    }, [ id, dispatch]);

    return (
        <div className="test-container">
            <div className="test-content">
                
                    <>
                        <FontAwesomeIcon icon={faExclamationCircle} className="test-icon failure" />
                        <h1 className="test-heading">Transaction Failed!</h1>
                        <p className="test-message">We are unable to process your transaction at the moment. Please try again later.</p>
                        <Link className="retry-button"to={`/payment/${id}`} >Retry</Link>
                    </>
             
            </div>
        </div>
    );
}

export default Test;
