import keys from "./formation.keys";
export const InitialFormationState = {
  payload: false,
  formations: [],
  transactions: [],
  status: null,
};
export const FormationReducer = (
  state = { ...InitialFormationState },
  action
) => {
  switch (action.type) {
    case keys.set_formations:
      return { ...state, formations: action.value, payload: false };
    case keys.get_formations_country:
      return { ...state, formations: action.value, payload: false };
    case keys.get_formation_id:
      return { ...state, formations: action.value, payload: false };
    case keys.setDemands:
      return { ...state, demands: action.value, payload: false };
    case keys.set_receiptData:
      return { ...state, receiptData: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    case keys.set_transactions:
      return { ...state, transactions: action.value };
    case keys.set_status:
      return { ...state, status: action.value };
    default:
      return state;
  }
};
