const keys = {
  setUnivs: "setUnivs",
  setFormas: "setFormas",
  setDemands: "setDemands",
  payload: "university_payload",
  setSelUniv: "setSelUniv",
  all: "all_university",
};

export default keys;
