import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col } from "reactstrap";
import { check_ribPayment } from "../redux/formation/formation.actions";
import OpenDescription from "../partials/universityProfile/popups/OpenDescription";
import AuthNavBar from "./../partials/header/AuthNavBar";
import Footer from "./../partials/footer/Footer";
import libraby from "../assets/images/library.webp";
import university_paris_logo from "../assets/images/university_paris_logo.webp";
import double_cercle from "../assets/images/double-cercle.webp";
import { useParams } from "react-router-dom";
import "../assets/styles/CityUniversities.css";
import { useSelector, useDispatch } from "react-redux";
import paris_universities from "../assets/images/paris_univserties.webp";
import { GetFormationByCountry, GetDemands } from "../redux/formation/formation.actions";
import "../assets/styles/CityUniversities.css";
import countries_background from "../assets/images/countries2.webp";
import france_background from "../assets/images/france_background.webp";
import belgique_background from "../assets/images/belgique_background.webp";
import canada_background from "../assets/images/canada_background.webp";
import espagne_background from "../assets/images/espagne_background.webp";
import tunis_background from "../assets/images/tunis_background.webp";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { isUser } from "../custom/roles";
import { Helmet } from 'react-helmet';

function CityUniversities() {
  const { city } = useParams();
  const dispatch = useDispatch();
  const [Item, setItem] = useState({});
  const [selectedOption, setSelectedOption] = useState("all");

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const options = ['all', 'with bac', 'without bac'];
  const formations = useSelector((state) => state.FormationReducer.formations);
  const user = useSelector((state) => state.UserReducer.user);
  let demands = useSelector((state) => state.FormationReducer.demands);
  
  const backgrounds = {
    france: france_background,
    belgium: belgique_background,
    canada: canada_background,
    spain: espagne_background,
    tunisia: tunis_background,
    countries: countries_background,
  };
  if (!(city.toLowerCase() in backgrounds)){
    backgrounds[city] = countries_background;
  }

  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (selected !== "") {
      dispatch(GetFormationByCountry(selected));
    }
  }, [selected]);

  useEffect(() => {
    dispatch(GetDemands());
  }, []);

  const [descDialogue, setdescDialogue] = useState(false);
  const opendescDialogue = (row) => {
    setdescDialogue(true);
    setItem({ ...row });
  };

  const handleClose = () => {
    setdescDialogue(false);
    setItem({});
  };

  const handleClick = (id) => {
    dispatch(check_ribPayment(id));
  };

  const SearchSub = (user, formaId) => {
    let result = "no";

    demands.forEach((x) => {
      if (x.User?.email === user?.email && x.formation === formaId) {
        if (x.statut === "verfie") {
          result = ["succ", x._id];
        } else if (x.statut === "attente") {
          result = ["pen", x._id];
        } else if (x.statut === "refuse") {
          result = ["ref", x.motif, x._id];
        }
      }
    });

    return result;
  };

  useEffect(() => {
    if (city === "tunisia" || city === "tunisie") {
      setSelected("tunisia");
    } else if (city === "france" || city === "france") {
      setSelected("france");
    } else if (city === "belgique" || city === "belgium") {
      setSelected("belgium");
    } else if (city === "canada" || city === "canada") {
      setSelected("canada");
    } else if (city === "spain" || city === "espagne") {
      setSelected("spain");
    } else if (city === "other countries" || city === "autres pays") {
      setSelected("countries");
    } else {
      setSelected(city);
    }
  }, [city]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 992 });

  // Filter formations based on the selected option
  const filteredFormations = formations.filter((formation) => {
    if (selectedOption === "all") return true;
    if (selectedOption === "with bac") return formation.avecBac;
    if (selectedOption === "without bac") return !formation.avecBac;
    return false;
  });

  return (
    <>
      <Helmet>
        <title>{city} | Success Study</title>
      </Helmet>
      <AuthNavBar />
      <section className="position-relative header_container">
        <div className="shadow-image"></div>
        <img
          src={backgrounds[selected.toLowerCase()]}
          className="city_img_55"
          alt="paris formations"
        />
        <div className="position-absolute top-50 start-50 translate-middle title_container" style={{ width: "85%", textAlign: "center" }}>
          <h1 className="header_title">{city.toUpperCase()}</h1>
        </div>
        <div className="breadcrumb_container position-absolute bottom-0">
          <Breadcrumb separator={<span> {">"} </span>}>
            <BreadcrumbItem>
              <Link to="/" className="text-white">
                {t('city.destination')}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/france" className="text-white" style={{ textTransform: "capitalize" }}>
                {city}
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      </section>
      <section className="universities_list_container position-relative">
        <div className={isMobile ? "list_title_container d-flex  my-5" : "list_title_container d-flex mt-5"}>
          <div className="yellow_box w-100"></div>
          <h3 className="color_second title_main city_title" style={{ textTransform: "capitalize" }}>{city}</h3>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
  
  {options.map((option, index) => (
    <div
      key={index}
      onClick={() => handleSelect(option)}
      style={{
        padding: '10px 20px',
        margin: '10px',
        border: `2px solid ${selectedOption === option ? 'white': '#ffb703 '}`, // Change border color based on selection
        cursor: 'pointer',
        backgroundColor: selectedOption === option ? '#ffb703' : 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        fontSize:'15px',
        fontWeight: 'bold',
        color:`${selectedOption === option ? 'white' : '#ffb703 '} `,
        transition: 'background-color 0.3s ease', // Smooth transition for hover effect
      }}
    >
      {
      (option==="all") ? ( t('uni_params.all')):((option==="with bac")? (t('uni_params.with')):(t('uni_params.without')) )
      }
    </div>
  ))}
</div>
        <div className="list_container d-flex flex-column justify-content-center align-items-center">
          {filteredFormations.length > 0 ? (
            filteredFormations.map((item, key) => {
              let st = SearchSub(user, item._id);
              return (
                <div key={key} className="box_container formation_card_55 my-3 px-5">
                  <div className="text-center">
                    <h4 className="color_second mb-4 formation_title">
                      {item.nom}
                    </h4>
                  </div>
                  <div className="w-100">
                    <h5 className="title_second color_main description_formation text-justify description-limit" style={{ wordWrap: 'break-word' }}>
                      {item.description}
                    </h5>
                    <div className="d-flex align-items-end">
                      <p style={{ fontSize: "14px", cursor: "pointer" }} className="color_second" onClick={() => opendescDialogue(item)}>
                        {t('uni_params.OpenDescription')}
                      </p>
                      <button
                        type="button"
                        className="btn btn-outline-primary position-relative p-3 rounded-0 border-0"
                        onClick={() => opendescDialogue(item)}
                        style={{ borderColor: '#4e86e4', color: '#4e86e4' }}
                      >
                        <i className="fas fa-book-reader fa-2x color_second"></i>
                      </button>
                    </div>
                    <h5 className="title_second color_second description_formation" style={{ fontSize: "14px", maxWidth: "450px" }}>
                      <span>{t("uni_params.price")}: {item.price} {item.devise}</span>
                      <span style={{ margin: "0 10px" }}>|</span>
                      <span>{t("uni_params.duration")}: {item.duree} {t("uni_params.week")}</span>
                      <span style={{ margin: "0 10px" }}>|</span>
                      <span>{item.avecBac ? t("user.with") : t("user.not_with")}</span>
                    </h5>
                    <div className="w-100 m-0 p-0">
                      <div className="row">
                        <div className="actions-right d-flex align-items-center justify-content-center">
                          {isUser(user) && st === "no" && (
                            <Link to={`/send_demand/${item.uni[0]._id}/${item._id}`} style={isMobile ? { width: "100%", textAlign: "center" } : { width: "50%", textAlign: "center" }}>
                              <button className="btn send_btn_style main_btn" style={{ fontSize: "14px!important" }}>
                                {t('university.send')}
                              </button>
                            </Link>
                          )}
                          {isUser(user) && st[0] === "pen" && (
                            <div className="alert alert-warning py-4 text-center" style={isMobile ? { fontSize: "16px", width: "100%" } : { fontSize: "16px", width: "50%" }}>
                              {t('university.pen')}
                            </div>
                          )}
                          {isUser(user) && st[0] === "succ" && (
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                              <div className="alert alert-success py-4 text-center" style={isMobile ? { fontSize: "16px", width: "100%" } : { fontSize: "16px", width: "70%" }}>
                                {(item.uni[0].fullname.trim() !== "" ? item.uni[0].fullname : item.uni.nom)} {t('university.accepted')}
                              </div>
                              <button className="alert alert-success py-4 text-center" style={isMobile ? { fontSize: "16px", width: "100%" } : { fontSize: "16px", width: "70%", width: "fit-content" }} onClick={() => handleClick(st[1])}>
                                Passez au paiement
                              </button>
                            </div>
                          )}
                          {isUser(user) && st[0] === "ref" && (
                            <div className="w-100 text-center">
                              <div className="alert alert-danger py-4" style={{ fontSize: "16px", width: "100%" }}>
                                <p>{t('university.rejected')}</p>
                                <p style={{ fontWeight: "700" }}>{t('university.motif')}</p>
                                <p>{st[1]}</p>
                              </div>
                              <Link to={`/send_demand/${item.uni[0]._id}/${item._id}`} style={isMobile ? { width: "100%", textAlign: "center" } : { width: "50%", textAlign: "center" }}>
                                <button className="btn send_btn_style main_btn" style={{ fontSize: "14px!important" }}>
                                  {t('university.resend')}
                                </button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h1 className="city_title text-center p-5">
              {t('city.not_available_formation')}
            </h1>
          )}
        </div>
      </section>
      <Footer />
      {descDialogue && <OpenDescription open={descDialogue} onClose={handleClose} item={Item} />}
    </>
  );
}

export default CityUniversities;
