import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "./Payment.css"; // Import your CSS file for styling
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector
import { get_rib_info } from "../redux/formation/formation.actions";

function Test() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const receiptData = useSelector(state => state.FormationReducer.receiptData); // Assuming receiptData is stored in Redux state
    const [processing, setProcessing] = useState(true); // State to track processing status

    useEffect(() => {
      dispatch(get_rib_info(id));
      console.log('this is reciept data',receiptData)
    }, [id])
    

    const handleRetry = () => {
        // Reload the page to retry the transaction
        window.location.reload();
    };

    return (
        <div >
            
                <div className="test-content" style={{marginTop:"20px"}}>
                    <FontAwesomeIcon icon={faSpinner} style={{marginTop:"20px",fontSize:"8rem",color:'#f4ba41ff'}} spin />
                    <h1 className="test-heading">Processing Your Transaction</h1>
                    <p className="test-message">Thank you for providing us with the reciept </p>
                    <p className="test-message">we are processing your transaction to confirm your transaction</p>
                    
                </div>
             {receiptData  && (
                <div className="test-content">
                    {receiptData && receiptData.formation && receiptData.universite && receiptData.user && (
                        <div className="receipt-table">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="label">Platform:</td>
                                        <td className="value">SUCCESS STUDY</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Order ID:</td>
                                        <td className="value">{id}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">User :</td>
                                        <td className="value">{receiptData.user.firstName}{' '}{receiptData.user.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Formation:</td>
                                        <td className="value">{receiptData.formation.nom}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">University:</td>
                                        <td className="value">{receiptData.universite.nom}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Amount:</td>
                                        <td className="value">{receiptData.montant} {receiptData.devise}</td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>)}
          
        </div>
    );
}

export default Test;
